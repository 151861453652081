import React, { useEffect, useState } from 'react';
import GradientButton, { GradientButtonProps } from './GradientButton';
import { useI18n } from '../context/I18nContext';
import { Resource } from '../types/appsync-types';
import { SelectResourceModal } from './modal/SelectResourceModal';
import { useBitwards } from '../bitwards/BitwardsContext';
import { ResourceLockButton } from './ResourceLockButton';

export type UnlockButtonProps = Omit<GradientButtonProps, 'children'> & {
  children?: React.ReactNode;
  resources: (Resource | undefined | null)[] | undefined | null;
};

export function UnlockButton(props: UnlockButtonProps) {
  const { onPress, resources, disabled, loading, children, ...other } = props;

  const { I18n } = useI18n();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (disabled) {
      setIsModalVisible(false);
    }
  }, [disabled]);

  if (!resources) {
    return (
      <GradientButton icon={'lock'} disabled>
        {children ?? I18n.t('unlockButton.unlock')}
      </GradientButton>
    );
  }

  if (resources?.length < 2) {
    return (
      <ResourceLockButtonWithResourceContext {...props} resources={resources}>
        {children ?? I18n.t('unlockButton.unlock')}
      </ResourceLockButtonWithResourceContext>
    );
  }

  return (
    <>
      <GradientButton
        icon={'lock'}
        disabled={disabled}
        loading={loading}
        onPress={() => setIsModalVisible(true)}
        {...other}
      >
        {children ?? I18n.t('unlockButton.selectDoor')}
      </GradientButton>
      {isModalVisible && (
        <SelectResourceModal
          resources={resources}
          isVisible={isModalVisible}
          loading={loading}
          onDismiss={() => setIsModalVisible(false)}
          disabled={disabled}
          onPress={onPress}
        />
      )}
    </>
  );
}

export type ResourceLockButtonWithResourceContextProps = GradientButtonProps & {
  resources: (Resource | undefined | null)[];
};

function ResourceLockButtonWithResourceContext({
  onPress,
  resources,
  disabled,
  loading,
  children,
  ...other
}: ResourceLockButtonWithResourceContextProps) {
  const { accessResource, loading: bitwardsLoading } = useBitwards();

  // const visibleResource = visibleResources.find((resource) => resource.id === resources[0]?.resourceId);
  return (
    <ResourceLockButton
      resourceId={resources[0]?.resourceId}
      // signalStrength={rssiToSignalStrength(visibleResource?.rssi)}
      disabled={disabled}
      loading={loading || bitwardsLoading}
      accessResource={accessResource}
      onPress={onPress}
      {...other}
    >
      {children}
    </ResourceLockButton>
  );
}

import React, { FC, useEffect, useState } from 'react';
import { Image, ImageSourcePropType, LayoutChangeEvent, View, ViewProps } from 'react-native';
import { useTheme } from 'react-native-paper';
import Typography, { TextVariant } from '../../components/Typography';
import useScreenSize from '../../hooks/useScreenSize';

export interface SlideProps extends ViewProps {
  title: string;
  description: string;
  image?: ImageSourcePropType;
  bottomAreaOnLayout?: (event: LayoutChangeEvent) => void;
}

const Slide: FC<SlideProps> = ({ title, description, image, children, bottomAreaOnLayout, ...other }) => {
  const { colors } = useTheme();
  const { windowHeight, windowWidth } = useScreenSize();

  const [titleFontVariant, setTitleFontVariant] = useState<TextVariant>('h2');
  const [descriptionFontVariant, setDescriptionFontVariant] = useState<TextVariant>('h6');

  useEffect(() => {
    const titleFontSize = () => {
      if (windowHeight >= 650) {
        if (windowWidth >= 400) {
          return 'h3';
        }
        return 'h4';
      }
      if (windowHeight < 650 && windowHeight > 500) {
        if (windowWidth >= 350) {
          return 'h4';
        }
      }
      return 'h5';
    };

    const descriptionFontSize = () => {
      if (windowHeight >= 650) return 'sub1';
      if (windowHeight < 650 && windowHeight > 500) return 'sub2';
      return 'sub3';
    };

    setTitleFontVariant(titleFontSize());
    setDescriptionFontVariant(descriptionFontSize());
  }, [windowHeight, windowWidth]);

  const renderChildren = () => {
    if (windowHeight > 350) {
      if (image) {
        return (
          <Image
            style={{
              height: '100%',
              width: '100%',
              resizeMode: 'contain',
              position: 'absolute',
              bottom: 0,
            }}
            source={image}
          />
        );
      }
      return children;
    }
    return null;
  };

  return (
    <View style={{ height: '100%', display: 'flex' }}>
      {/* Top text area */}
      <View
        style={{
          paddingHorizontal: 40,
          paddingTop: 60,
          paddingBottom: 20,
          minHeight: 210,
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <Typography variant={titleFontVariant} color={colors.primary}>
          {title}
        </Typography>
        <Typography variant={descriptionFontVariant} style={{ marginTop: 11 }} color={colors.onBackground}>
          {description}
        </Typography>
      </View>

      {/* Bottom image area */}
      <View style={{ flexShrink: 1, flexGrow: 1 }} onLayout={bottomAreaOnLayout}>
        {renderChildren()}
      </View>
    </View>
  );
};

export default Slide;

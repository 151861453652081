import * as React from 'react';
import { Appbar, Button, Text, TouchableRipple, useTheme } from 'react-native-paper';
import { StackHeaderProps, StackNavigationOptions, TransitionPresets } from '@react-navigation/stack';
import { Platform, useWindowDimensions, View } from 'react-native';
import { DrawerActions } from '@react-navigation/core';
import { useCallback, useMemo, useState } from 'react';
import { addDays, isSameDay, isSameMinute } from 'date-fns';
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { getTimeZone } from 'react-native-localize';
import { UiIcon, uiIconSourceGenerator } from './SimpleListIcon';
import { DEFAULT_DURATION_STEP_MINS, useMapViewFilter } from '../context/MapViewFilterContext';
import Spacer from './Spacer';
import { useI18n } from '../context/I18nContext';
import { useNowMinutes, useToday } from '../hooks/useNow';
import { capitalizeFirstLetter, roundToNextMinutes, roundToPrevMinutes } from '../utils/helpers';
import { FilterButton } from './FilterButton';
import DateTimePickerModal from './modal/DateTimePickerModal';
import { ArrowButton } from './LeftRightPicker';
import { setTz, startOfDayTz } from '../utils/date-utils';
import Typography from './Typography';
import { MAX_ADVANCE_RESERVE_DAYS } from '../apis/reservationApis';
import Images from '../../assets/images';

// TODO: Once we implement search and filtering, this can be turned to true.
const SEARCH_WORKS = false;

const SHOW_FILTER = false;

const ExpandFilter: React.FC<{ onPress: () => void; isExpanded: boolean; hasFilters: boolean }> = ({
  isExpanded,
  hasFilters,
  onPress,
}) => {
  const { colors, roundness } = useTheme();

  const iconElem = useMemo(() => {
    if (isExpanded) {
      return <UiIcon name={'arrow-up'} size={16} color={colors.secondary} style={{ textAlign: 'center' }} />;
    }
    if (hasFilters) {
      return (
        <UiIcon
          name={'filter-menu-outline'}
          set={'material-community'}
          size={20}
          color={colors.primary}
          style={{ textAlign: 'center' }}
        />
      );
    }
    return (
      <View>
        {SEARCH_WORKS ? <Text style={{ fontSize: 12, opacity: isExpanded ? 0 : 1 }}>{'Kaikki'}</Text> : null}
        <UiIcon name={'arrow-down'} size={16} color={colors.secondary} style={{ textAlign: 'center' }} />
      </View>
    );
  }, [isExpanded, hasFilters, colors]);

  return (
    <TouchableRipple
      onPress={onPress}
      accessibilityRole="button"
      style={{
        flexDirection: 'row',
        margin: 8,
        marginTop: (56 - 36) / 2,
        /* 56 = App bar default height,  36 = Appbar.Action size */
        padding: 4,
        minHeight: 36,
        minWidth: 36,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: roundness,
      }}
    >
      {iconElem}
    </TouchableRipple>
  );
};

function MapFilterNavigatorHeader(props: StackHeaderProps) {
  const { navigation, back } = props;
  const { colors } = useTheme();
  const { filter, onChange, timeZone } = useMapViewFilter();
  const { I18n, formatDate, formatRelative, currentLocale } = useI18n();
  const todayOnDevice = useToday(getTimeZone()); // We need device local time to be passed to formatRelative
  const todayInPod = useToday(timeZone);
  const now = useNowMinutes();
  const { width } = useWindowDimensions();
  const showBackButton = !!back;
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [isDateTimePickerVisible, setDateTimePickerVisible] = useState<boolean>(false);
  const [dateTimeMode, setDateTimeMode] = useState<'date' | 'time'>('date');

  const dateStr = useMemo(() => {
    return capitalizeFirstLetter(
      formatRelative(
        filter.date,
        todayOnDevice,
        timeZone,
        isExpanded
          ? {
              dropDateIfRelative: false,
              dropWeekdayIfRelative: true,
              showDate: true,
              showWeekday: true,
              showTime: false,
            }
          : {
              dropDateIfRelative: true,
              showWeekday: false,
              showTime: false,
            },
      ),
    );
  }, [filter.date, formatRelative, isExpanded, timeZone, todayOnDevice]);

  const timeStr = useMemo(() => {
    const t = formatDate(filter.date, 'time-no-tz', timeZone);
    if (isSameMinute(filter.date, now)) {
      if (isExpanded) {
        return I18n.t('datetime.timeNow', { time: t });
      }
      return capitalizeFirstLetter(I18n.t('general.now'));
    }
    return t;
  }, [I18n, isExpanded, formatDate, filter.date, now, timeZone]);

  const onSetDate = useCallback(
    (newDate: Date) => {
      let validDate;
      if (newDate < now) validDate = now;
      else if (newDate > addDays(now, MAX_ADVANCE_RESERVE_DAYS)) validDate = addDays(now, MAX_ADVANCE_RESERVE_DAYS);
      else validDate = newDate;
      onChange({
        ...filter,
        date: validDate,
      });
    },
    [now, filter, onChange],
  );
  const handleReset = useCallback(() => {
    onSetDate(now);
  }, [now, onSetDate]);

  const handleLaterButton = useCallback(() => {
    onSetDate(roundToNextMinutes(filter.date, DEFAULT_DURATION_STEP_MINS));
  }, [filter.date, onSetDate]);

  const handleEarlierButton = useCallback(() => {
    onSetDate(roundToPrevMinutes(filter.date, DEFAULT_DURATION_STEP_MINS));
  }, [filter.date, onSetDate]);

  const handleShowTimePicker = useCallback(() => {
    setDateTimeMode('time');
    setDateTimePickerVisible(true);
  }, []);

  const handleShowDatePicker = useCallback(() => {
    setDateTimeMode('date');
    setDateTimePickerVisible(true);
  }, []);

  // TODO: Implement search
  const handleShowLocationSearch = useCallback(() => {}, []);
  const handleShowSeatPicker = useCallback(() => {}, []);
  const handleShowTypePicker = useCallback(() => {}, []);

  const onDateTimeCancel = useCallback(() => setDateTimePickerVisible(false), []);

  const onDateTimeConfirm = useCallback(
    (newDate: Date) => {
      setDateTimePickerVisible(false);
      if (newDate) {
        if (dateTimeMode === 'date') {
          onSetDate(
            setTz(
              filter.date,
              {
                year: newDate.getFullYear(),
                month: newDate.getMonth(),
                date: newDate.getDate(),
              },
              timeZone,
            ),
          );
        } else {
          onSetDate(
            setTz(
              filter.date,
              {
                year: newDate.getFullYear(),
                month: newDate.getMonth(),
                date: newDate.getDate(),
                hours: newDate.getHours(),
                minutes: newDate.getMinutes(),
                seconds: 0,
                milliseconds: 0,
              },
              timeZone,
            ),
          );
        }
      }
    },
    [dateTimeMode, filter.date, onSetDate, timeZone],
  );

  const canReset = !isSameMinute(now, filter.date);
  const showMenuButton = !showBackButton && width <= 600;
  return (
    (SHOW_FILTER || showMenuButton) && (
      <Appbar.Header
        style={{
          elevation: 0,
          backgroundColor: colors.inverseSurface,
          alignItems: SHOW_FILTER ? 'flex-start' : 'center',
          height: 'auto',
          minHeight: 56,
        }}
        accessibilityRole={'header'}
      >
        {showMenuButton ? (
          <View style={!SHOW_FILTER ? { flexGrow: 1, flexShrink: 0 } : undefined}>
            <Appbar.Action
              animated={false}
              accessibilityRole={'button'}
              // TODO: Accessibility label
              style={{
                marginTop: (56 - 36) / 2,
                // 56 = App bar default height,  36 = Appbar.Action size
              }}
              icon={uiIconSourceGenerator({
                name: showBackButton ? 'arrow-left' : 'menu',
                size: 21,
                color: colors.inverseOnSurface,
              })}
              onPress={() => {
                if (showBackButton) {
                  navigation.goBack();
                } else {
                  navigation.dispatch(DrawerActions.openDrawer());
                }
              }}
            />
          </View>
        ) : null}

        {SHOW_FILTER ? (
          <>
            <View
              style={{
                flexDirection: 'column',
                flexGrow: 1,
                flexShrink: 1,
                paddingTop: 10,
                marginLeft: showMenuButton ? undefined : 6,
              }}
            >
              {/* Search */}
              {isExpanded && SEARCH_WORKS ? (
                <>
                  <FilterButton title={'Near me'} icon={'magnifier'} onPress={handleShowLocationSearch} />
                  <Spacer />
                </>
              ) : null}

              <View style={{ flexDirection: isExpanded ? 'column' : 'row' }}>
                {/* Date */}
                <FilterButton
                  title={dateStr}
                  icon={'calendar'}
                  onPress={handleShowDatePicker}
                  resetVisible={!isSameDay(startOfDayTz(filter.date, timeZone), todayInPod)}
                  onResetPress={handleReset}
                />
                <Spacer type={isExpanded ? 'row' : 'column'} />

                {/* Time */}
                <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                  <FilterButton
                    title={timeStr}
                    icon={'clock'}
                    onPress={handleShowTimePicker}
                    resetVisible={
                      isSameDay(startOfDayTz(filter.date, timeZone), todayInPod) && !isSameMinute(filter.date, now)
                    }
                    onResetPress={handleReset}
                  />
                  {isExpanded ? (
                    <>
                      <Spacer type={'column'} />
                      <ArrowButton direction={'left'} onPress={handleEarlierButton} disabled={filter.date <= now} />
                      <Spacer type={'column'} />
                      <ArrowButton
                        direction={'right'}
                        onPress={handleLaterButton}
                        disabled={filter.date >= addDays(now, MAX_ADVANCE_RESERVE_DAYS)}
                      />
                    </>
                  ) : null}
                </View>
              </View>
              {/* Seats & Amenities */}
              {isExpanded && SEARCH_WORKS ? (
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 8 }}>
                  <FilterButton title={'1+'} icon={'people'} onPress={handleShowSeatPicker} />
                  <Spacer type={'column'} />
                  <FilterButton title={'All types'} icon={'tag'} onPress={handleShowTypePicker} />
                </View>
              ) : null}

              {isExpanded ? (
                <Button
                  mode="text"
                  compact={true}
                  style={{ alignSelf: 'flex-end', marginRight: -8 }}
                  onPress={handleReset}
                  disabled={!canReset}
                >
                  {I18n.t('general.reset')}
                </Button>
              ) : null}
              {timeZone !== getTimeZone() && (
                <View style={{ paddingVertical: 5 }}>
                  <Typography color={colors.inverseOnSurface} variant={'body2'}>{`${I18n.t(
                    'mapview.timezoneDialog.title',
                    {
                      zone: timeZone,
                    },
                  )} (${formatInTimeZone(filter.date, timeZone, 'zzz')})`}</Typography>
                </View>
              )}
            </View>

            {SEARCH_WORKS ? (
              <ExpandFilter
                isExpanded={isExpanded}
                hasFilters={canReset}
                onPress={() => setExpanded((prev) => !prev)}
              />
            ) : (
              <Spacer type="column" />
            )}

            <DateTimePickerModal
              isVisible={isDateTimePickerVisible}
              mode={dateTimeMode}
              date={utcToZonedTime(filter.date, timeZone)}
              is24Hour={true}
              locale={currentLocale}
              maximumDate={addDays(now, MAX_ADVANCE_RESERVE_DAYS)}
              minimumDate={utcToZonedTime(now, timeZone)}
              onConfirm={onDateTimeConfirm}
              onCancel={onDateTimeCancel}
            />
          </>
        ) : (
          <>
            <View>
              <Images.iconApp
                style={{ width: 144, height: 34 }}
                width={144}
                height={34}
                // setting resizeMode on web causes very long error message on console
                {...(Platform.OS !== 'web' ? { resizeMode: 'contain' } : {})}
              />
            </View>

            {/* Empty view for spacing */}
            <View style={{ flex: 1, marginRight: 46 }} />
          </>
        )}
      </Appbar.Header>
    )
  );
}

export const MapScreenOptions: StackNavigationOptions = {
  header: (props: StackHeaderProps) => <MapFilterNavigatorHeader {...props} />,
  headerTransparent: true,
  animationEnabled: true,
  presentation: 'card',
  headerMode: 'screen',
  ...TransitionPresets.SlideFromRightIOS, // Force iOS styled animation
};

import { useQuery, UseQueryOptions } from 'react-query';
import { appsyncGraphql, AppsyncResult } from '../apis/appsyncHelper';
import { sendMail } from '../apis/mailApis';
import {
  MutationApplyVoucherCodeArgs,
  MutationRemoveAPaymentMethodArgs,
  MutationSetDefaultPaymentArgs,
  QueryEstimatePriceAndHoldAmountArgs,
} from '../types/appsync-types';

export function requestDiscount(website: string, targetEmail: string, user: string) {
  return sendMail(
    `[Lead] Company ${website} should be contacted`,
    `Company ${website} (${targetEmail}) should be approached about signing up. Tip came from ${user}.`,
  );
}

getStripePayment.id = 'getStripePayment';

export function getStripePayment() {
  const query = /* GraphQL */ `
    query GetStripePayment {
      getStripePayment {
        last4
        exp_month
        exp_year
        brand
        id
        cvc_check
        is_default
        transactions {
          amount
          currency
          reservation {
            id
            from
            to
            userId
            paymentId
            paymentSecret
            poisId
            buildingRef
            reservedDay
            receiptUrl
            building {
              timezone
            }
          }
          card {
            id
            cvc_check
            last4
            exp_month
            exp_year
            brand
            is_default
          }
        }
      }
    }
  `;
  return appsyncGraphql<'getStripePayment'>(query, {});
}

export function useGetStripePaymentQuery(options?: UseQueryOptions<AppsyncResult<'getStripePayment'>>) {
  return useQuery<AppsyncResult<'getStripePayment'>>(getStripePayment.id, getStripePayment, options);
}

export function addStripePayment() {
  const mutation = /* GraphQL */ `
    mutation AddStripePayment {
      addStripePayment
    }
  `;
  return appsyncGraphql<'addStripePayment'>(mutation);
}

export function setDefaultPayment(input: MutationSetDefaultPaymentArgs) {
  const mutation = /* GraphQL */ `
    mutation SetDefaultPayment($card_id: ID!) {
      setDefaultPayment(card_id: $card_id)
    }
  `;
  return appsyncGraphql<'setDefaultPayment'>(mutation, { card_id: input.card_id });
}

export function removePaymentMethod(input: MutationRemoveAPaymentMethodArgs) {
  const mutation = /* GraphQL */ `
    mutation RemoveAPaymentMethod($card_id: ID!) {
      removeAPaymentMethod(card_id: $card_id)
    }
  `;
  return appsyncGraphql<'removeAPaymentMethod'>(mutation, { card_id: input.card_id });
}

export function useGetSpaceInfoWithPricing(vars: QueryEstimatePriceAndHoldAmountArgs) {
  const variables = {
    input: vars.input,
    openAt: vars.input?.from,
    id: vars.input?.spaceId,
  };
  return useQuery([useGetSpaceInfoWithPricing.id, ...Object.values(vars)], () => {
    const query = /* GraphQL */ `
      query GetPricingInfo($input: SpaceReservationInput, $id: ID!, $openAt: AWSDateTime) {
        getPoisByID(id: $id) {
          type
          depositAmountInCents
          availabilityStatus(from: $openAt) {
            nextFreeStart
            nextFreeEnd
            status
          }
          billingType
        }
        estimatePriceAndHoldAmount(input: $input) {
          price
          currency
        }
      }
    `;
    return appsyncGraphql<'getPoisByID' | 'estimatePriceAndHoldAmount'>(query, variables);
  });
}
useGetSpaceInfoWithPricing.id = 'estimatePriceAndHoldAmount';

export function useGetRecentReceipts() {
  return useQuery(['useGetRecentReceipts'], () => {
    const query = /* GraphQL */ `
      query GetRecentTransactions {
        getRecentTransactions {
          amount
          currency
          reservation {
            id
            status
            from
            to
            userId
            paymentId
            paymentSecret
            poisId
            buildingRef
            reservedDay
            receiptUrl
            building {
              timezone
            }
          }
          card {
            id
            cvc_check
            last4
            exp_month
            exp_year
            brand
            is_default
            transactions {
              amount
              currency
            }
          }
        }
      }
    `;
    return appsyncGraphql<'getRecentTransactions'>(query);
  });
}

useGetRecentCreditBalanceHistory.id = 'useGetRecentCreditBalanceHistory';

export function useGetRecentCreditBalanceHistory() {
  return useQuery([useGetRecentCreditBalanceHistory.id], () => {
    const query = /* GraphQL */ `
      query GetMyRecentCreditHistory {
        getMyRecentCreditHistory {
          transactionId
          userId
          balanceAfter
          change
          currency
          creditType
          internalNote
          sourceCode
          sourceName
          transactionTime
        }
      }
    `;
    return appsyncGraphql<'getMyRecentCreditHistory'>(query);
  });
}

export function applyVoucherCode(input: MutationApplyVoucherCodeArgs) {
  const mutation = /* GraphQL */ `
    mutation ApplyVoucherCode($code: String!) {
      applyVoucherCode(code: $code) {
        sub
        phonenumber
        firstname
        lastname
        organization
        creditBalance
        currency
        inviteCode
        language
        createdAt
        updatedAt
        createdat
        updatedat
      }
    }
  `;
  return appsyncGraphql<'applyVoucherCode'>(mutation, input);
}

import React, { FC } from 'react';
import { Surface, useTheme } from 'react-native-paper';
import { View } from 'react-native';
import { ChargeRate, Currency } from '../../types/appsync-types';
import Typography from '../Typography';
import Container from '../Container';
import { useI18n } from '../../context/I18nContext';

type Props = { chargeRates: ChargeRate[]; currency: Currency };

const units: [number, string][] = [
  [1, 'minute'],
  [60, 'hour'],
  [60 * 24, 'day'],
  [60 * 24 * 7, 'week'],
  [60 * 24 * 30, 'month'],
];

function getBestTimeUnit(minutes: number) {
  let bestUnit = units[0];
  units.forEach((unit) => {
    if (minutes >= unit[0]) {
      bestUnit = unit;
    }
  });
  return bestUnit;
}

export const SpacePricing: FC<Props> = ({ chargeRates, currency }) => {
  const { colors } = useTheme();
  const { formatCurrency, I18n } = useI18n();
  return (
    <Container>
      <Surface
        style={{
          paddingVertical: 10,
          marginVertical: 10,
          backgroundColor: colors.background,
          borderColor: colors.primary,
          borderWidth: 1,
        }}
      >
        <Container>
          <Typography variant="h3">{I18n.t('mapview.spaceInfo.pricing')}</Typography>
          {chargeRates.map((chargeRate) => {
            // TODO: Handle possible undefined values
            const [divisor, unit] = getBestTimeUnit(chargeRate.interval!);
            const minimumTimeString: string = `${Math.floor(chargeRate.min! / divisor)}`;
            const maximumTimeString: string = chargeRate.max ? `${Math.floor(chargeRate.max / divisor)}` : '';
            const currencyString = formatCurrency(chargeRate.price! / 100, currency);
            const pricingText = maximumTimeString
              ? `${minimumTimeString} - ${maximumTimeString} ${I18n.t(
                  'datetime.' + unit + 's',
                )}: ${currencyString} per ${I18n.t('datetime.' + unit)}`
              : `${currencyString} per ${I18n.t('datetime.' + unit)}`;
            return (
              <View key={chargeRate.min}>
                <Typography variant="body1">{pricingText}</Typography>
              </View>
            );
          })}
        </Container>
      </Surface>
    </Container>
  );
};

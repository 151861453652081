import React, { useCallback, useState } from 'react';
import { CompositeScreenProps } from '@react-navigation/native';
import { Image, Linking, Platform, Share, StyleSheet, View } from 'react-native';
import { Button, List, Snackbar, useTheme } from 'react-native-paper';
import Clipboard from '@react-native-clipboard/clipboard';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../context/I18nContext';
import ScrollablePage from '../components/ui/ScrollablePage';
import SpaceSchedule from '../components/ui/SpaceSchedule';
import Spacer from '../components/Spacer';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import { AmenityIcon } from './MapView/PodInformationCard';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { useGetSpaceInfo } from '../apis/mapApis';
import Images from '../../assets/images';
import { SpacePricing } from '../components/ui/SpacePricing';
import { BillingType, Currency } from '../types/appsync-types';
import Typography from '../components/Typography';

export type SpaceInfoRouteParams = { spaceId: string };

type Props = CompositeScreenProps<StackScreenProps<MapStackRouteParams, 'SpaceInfo'>, AppDrawerScreenProp>;

const AmenityItem: React.FC<{
  amenity: 'maxCapacity' | 'screen' | 'whiteboard' | 'usbPower' | 'powerOutlet' | 'bikeWash';
  title: string;
}> = ({ amenity, title }) => {
  const { colors } = useTheme();
  return (
    <List.Item
      style={{ paddingVertical: 0 }}
      titleStyle={{ fontSize: 14, color: colors.inverseOnSurface }}
      left={() => (
        <List.Icon icon={(props) => <AmenityIcon name={amenity} iconColor={colors.inverseOnSurface} {...props} />} />
      )}
      title={title}
      titleNumberOfLines={2}
    />
  );
};
export const SpaceInfo: React.FC<Props> = ({ navigation, route }) => {
  const { I18n } = useI18n();
  const { colors } = useTheme();
  const mp = useMixpanel();
  const [copiedSnackbarVisible, setCopiedSnackbarVisible] = useState<boolean>(false);
  const spaceInfoResult = useGetSpaceInfo(route.params?.spaceId);
  const spaceInfo = spaceInfoResult.data?.data?.getPoisByID;

  const handleSharePodDetails = useCallback(async () => {
    if (Platform.OS !== 'web') {
      const result = await Share.share(
        {
          title: `#${route.params?.spaceId}`,
          url: `https://app.biketti.tech/c/space/${route.params?.spaceId}`,
        },
        { subject: I18n.t('mapview.spaceInfo.shareSubject') },
      );
      if (result.action === Share.sharedAction) {
        // action when shared
        mp?.track('Share Bicyclehut', { spaceId: route.params?.spaceId });
        mp?.getPeople().increment('Shared bicyclehut links', 1);
      } else {
        // action when dismiss
      }
    } else {
      Clipboard.setString(`https://app.biketti.tech/c/space/${route.params.spaceId}`);
      setCopiedSnackbarVisible(true);
    }
  }, [I18n, route.params?.spaceId, mp]);

  const renderDetails = () => {
    return (
      <>
        <AmenityItem
          amenity="maxCapacity"
          title={I18n.t('mapview.spaceInfo.amenities.maxCapacity', { count: spaceInfo?.metadata?.maxCapacity })}
        />
        {/* <AmenityItem amenity="bikeWash" title="Bike wash station" /> */}
        {/* {info.screen ? (
          <AmenityItem
            amenity="screen"
            title={I18n.t('mapview.spaceInfo.amenities.screen', {
              type: info.screen,
            })}
          />
        ) : null}
        {info.powerOutlets ? (
          <AmenityItem
            amenity="powerOutlet"
            title={I18n.t('mapview.spaceInfo.amenities.powerOutlets', {
              count: info.powerOutlets,
            })}
          />
        ) : null}
        {info.whiteboard ? (
          <AmenityItem amenity="whiteboard" title={I18n.t('mapview.spaceInfo.amenities.whiteboard')} />
        ) : null}
        {info.usbAPower ? (
          <AmenityItem
            amenity="usbPower"
            title={I18n.t('mapview.spaceInfo.amenities.usbAPower', {
              count: info.usbAPower,
            })}
          />
        ) : null}
        {info.usbCPower ? (
          <AmenityItem
            amenity="usbPower"
            title={I18n.t('mapview.spaceInfo.amenities.usbCPower', {
              count: info.usbCPower,
            })}
          />
        ) : null} */}
        <Spacer type="section" />
      </>
    );
  };

  return (
    <>
      <ScrollablePage
        summary={{
          title: `${spaceInfo?.title} #${route.params?.spaceId}`,
          description: renderDetails(),
          button: {
            label: I18n.t('share.share'),
            onPress: handleSharePodDetails,
          },
          icon: (props) => {
            return (
              <Image
                source={
                  Images[(spaceInfo?.metadata?.imageUrl as 'BIK500x500px' | 'CYKLON500x500px') || 'BIK500x500px'] ||
                  Images.BIK500x500px
                }
                style={{
                  width: props.size,
                  height: props.size,
                }}
              />
            );
          },
        }}
      >
        <Spacer type="section" />
        <Spacer />
        <SpaceSchedule spaceId={route.params.spaceId} />
        <Spacer />
        {spaceInfo?.billingType === BillingType.PAY_AS_YOU_GO && spaceInfo.chargeRates && (
          <SpacePricing chargeRates={spaceInfo.chargeRates} currency={spaceInfo.currency || Currency.EUR} />
        )}
        <View style={[styles.card, { backgroundColor: colors.surface }]}>
          <Typography variant={'h6'}>{spaceInfo?.building?.title}</Typography>
          <Typography variant={'body2'} style={{ marginTop: 5 }}>
            {spaceInfo?.building?.address}
          </Typography>
          <Image style={styles.addressImage} source={{ uri: spaceInfo?.building?.imageUrl! }} />
          <View style={styles.cardAction}>
            <Button
              onPress={() => {
                if (spaceInfo?.building?.websiteUrl) {
                  Linking.openURL(spaceInfo?.building?.websiteUrl);
                }
              }}
            >
              {I18n.t('bookingDetail.actions.website')}
            </Button>
          </View>
        </View>
      </ScrollablePage>
      <Snackbar visible={copiedSnackbarVisible} duration={3000} onDismiss={() => setCopiedSnackbarVisible(false)}>
        {I18n.t('share.copiedToClipboard')}
      </Snackbar>
    </>
  );
};

const styles = StyleSheet.create({
  card: {
    borderRadius: 4,
    padding: 20,
    marginTop: 26,
  },
  cardAction: {
    flexDirection: 'row',
    marginHorizontal: -15,
    flexWrap: 'wrap',
  },
  addressImage: {
    marginHorizontal: -20,
    height: 150,
    backgroundColor: '#ddd',
    marginVertical: 20,
  },
});

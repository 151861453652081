/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react';
import { Platform, View, TouchableOpacity } from 'react-native';
import { ActivityIndicator, Button } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import color from 'color';
import Typography from './Typography';
import { UiDefaultIconName, UiIcon, UiIconType } from './SimpleListIcon';
import { useBicyclehutTheme } from '../utils/paperTheme';

type ButtonProps = React.ComponentProps<typeof Button>;
export type GradientButtonProps = Omit<ButtonProps, 'icon'> & {
  loading?: boolean;
  rounded?: boolean;
  narrow?: boolean;
  fixedHeight?: boolean;
  contentCentered?: boolean;
  icon?: UiIconType | UiDefaultIconName;
  rightIcons?: (UiIconType | UiDefaultIconName)[];
};

const GradientButton = ({
  children,
  loading,
  disabled,
  style,
  rounded,
  icon,
  rightIcons,
  narrow,
  fixedHeight = true,
  contentCentered = true,
  ...otherProps
}: PropsWithChildren<GradientButtonProps>) => {
  const { roundness, dark, custom, colors } = useBicyclehutTheme();
  const disabledBackgroundColor = color(dark ? '#ffffff' : '#000000')
    .alpha(0.12)
    .rgb()
    .string();
  const renderButton = () => {
    return (
      <TouchableOpacity
        disabled={disabled || loading}
        style={[
          {
            width: '100%',
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            marginBottom: 0,
            backgroundColor: disabled || loading ? disabledBackgroundColor : '#ffffff00',
            padding: 6,
            display: 'flex',
            alignItems: 'center',
            borderRadius: rounded ? 24 : roundness,
            justifyContent: 'center',
          },
          fixedHeight ? { height: 47 } : { minHeight: 47 },
        ]}
        onPress={otherProps.onPress}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            paddingVertical: Platform.OS !== 'web' ? 4 : 0,
            paddingHorizontal: 10,
          }}
        >
          {icon && rightIcons && (
            <UiIcon
              {...(typeof icon === 'string' ? { name: icon } : { ...icon })}
              size={24}
              color={colors.secondary}
              style={{ marginRight: 10 }}
            />
          )}
          <View
            style={[
              {
                flexDirection: 'row',
                alignItems: 'center',
                flexShrink: 1,
              },
              !rightIcons && contentCentered ? { marginHorizontal: 'auto' } : undefined,
            ]}
          >
            {/* Loading indicator, if loading */}
            {loading ? <ActivityIndicator size={16} style={{ marginRight: 10 }} color={colors.secondary} /> : null}

            {/* Button icon, if specified */}
            {icon && !rightIcons && (
              <UiIcon
                {...(typeof icon === 'string' ? { name: icon } : { ...icon })}
                size={24}
                color={colors.secondary}
                style={{ marginRight: 10, marginLeft: 'auto' }}
              />
            )}

            {/* Actual button content. */}
            <Typography variant={'button'} style={{ flexShrink: 1 }}>
              {children}
            </Typography>
          </View>

          {/* Icons aligned to the right, if specified */}
          {rightIcons?.length && (
            <View style={{ flexDirection: 'row', marginLeft: 'auto' }}>
              {rightIcons?.map((rightIcon) => (
                <UiIcon
                  {...(typeof rightIcon === 'string' ? { name: rightIcon } : { ...rightIcon })}
                  key={typeof rightIcon === 'string' ? rightIcon : rightIcon.name}
                  size={24}
                  color={colors.secondary}
                />
              ))}
            </View>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  const renderGradientButton = () => {
    return (
      <LinearGradient {...custom.gradientLayer1} style={{ borderRadius: rounded ? 24 : roundness }}>
        <LinearGradient {...custom.gradientLayer2} style={{ borderRadius: rounded ? 24 : roundness }}>
          {renderButton()}
        </LinearGradient>
      </LinearGradient>
    );
  };
  return (
    <View
      style={[
        {
          flexDirection: narrow ? 'row' : 'column',
          justifyContent: 'center',
        },
        style,
      ]}
    >
      {loading || disabled ? renderButton() : renderGradientButton()}
    </View>
  );
};
export default GradientButton;

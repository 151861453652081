import React, { useContext, createContext, useMemo, useState, useCallback } from 'react';
import { useWindowDimensions } from 'react-native';
import { Modal, Portal } from 'react-native-paper';
import { BlurOverlay } from '../components/BlurOverlay';

type ModalOptionType = {
  content: React.ReactNode;
  onClose?: () => void;
  onDismiss?: () => void;
};

interface ModalContextValue {
  openModal: (options: ModalOptionType) => void;
  closeModal: (options?: ModalOptionType) => void;
  isVisible: boolean;
}

export const ModalContext = createContext<ModalContextValue | null>(null);

export function useModal() {
  const value = useContext(ModalContext);
  if (!value) {
    throw new Error('App Modal is out of reach');
  }
  return value;
}

export function ModalContextProvider({ children }: { children: React.ReactNode }) {
  const [currentOptions, setCurrentOptions] = useState<ModalOptionType>();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { width, height } = useWindowDimensions();

  const handleOpenModal = useCallback((opts: ModalOptionType) => {
    setCurrentOptions((prevOptions) => {
      if (prevOptions) {
        if (prevOptions.onDismiss) {
          prevOptions.onDismiss();
        }
        if (prevOptions?.onClose) {
          prevOptions.onClose();
        }
      }
      return opts;
    });
    setIsVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsVisible(false);
    setCurrentOptions((prevOptions) => {
      if (prevOptions?.onClose) {
        prevOptions.onClose();
      }
      return undefined;
    });
  }, []);

  const handleDismissModal = useCallback(() => {
    setIsVisible(false);
    setCurrentOptions((prevOptions) => {
      if (prevOptions?.onDismiss) {
        prevOptions.onDismiss();
      }
      if (prevOptions?.onClose) {
        prevOptions.onClose();
      }
      return undefined;
    });
  }, []);

  const value = useMemo(() => {
    return { openModal: handleOpenModal, closeModal: handleCloseModal, isVisible };
  }, [handleCloseModal, handleOpenModal, isVisible]);

  return (
    <ModalContext.Provider value={value}>
      <Portal>
        {isVisible && (
          <BlurOverlay>
            <Modal
              dismissable
              visible={isVisible}
              onDismiss={handleDismissModal}
              contentContainerStyle={{
                borderRadius: 5,
                maxWidth: Math.min(380, 0.9 * width),
                maxHeight: Math.min(660, 0.8 * height),
                alignSelf: 'center',
              }}
            >
              {currentOptions?.content}
            </Modal>
          </BlurOverlay>
        )}
      </Portal>
      {children}
    </ModalContext.Provider>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, View } from 'react-native';
import { HelperText } from 'react-native-paper';
import { useMutation, useQueryClient } from 'react-query';
import { addMinutes, differenceInMinutes } from 'date-fns';
import type { ConfirmPaymentError, GooglePayError, StripeError } from '@stripe/stripe-react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { useI18n } from '../../context/I18nContext';
import { cancelReservation, extendReservation, invalidateReservationData } from '../../apis/reservationApis';
import ConfirmationRow, { ModalRowStyle } from '../components/ConfirmationRow';
import { UseCreditsRow } from '../components/UseCreditsRow';
import { AmountDueRow } from '../components/AmountDueRow';
import Container from '../../components/Container';
import { CANNOT_BOOK } from '../../utils/errorCodes';
import { flattenDict } from '../../utils/helpers';
import { trackExtendReservation } from '../../utils/mixpanelUtils';
import { useGetSpaceInfoWithPricing } from '../paymentApis';
import { useMyProfileQuery } from '../../apis/appsyncApis';
import { CardPaymentMethod, useActivePayment } from '../MapViewActivePaymentContext';
import { NavigationDialog } from '../../screens/CommonDialogs';
import { CompoundReservation, PaymentMethodOption } from '../../types/misc.types';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import { ArrowButton } from '../../components/LeftRightPicker';
import * as Sentry from '../../components/sentry/sentry';
import { useStripe, usePlatformPay } from '../utils/stripe';
import { AppsyncData } from '../../apis/appsyncHelper';
import { PodStatus, Reservation, ReservationStatus } from '../../types/appsync-types';
import Typography from '../../components/Typography';

export interface ExtendReservationDialogRouteParams {
  compoundReservation: CompoundReservation;
}

type extendFail =
  | { type: 'PAYMENT'; error: StripeError<ConfirmPaymentError | GooglePayError> }
  | { type: 'CANCELLED'; response: AppsyncData<'extendAReservation'>['extendAReservation'] }
  | { type: 'ENDED'; response: AppsyncData<'extendAReservation'>['extendAReservation'] }
  | { type: 'TIMESLOT'; error: Error }
  | { type: 'UNEXPECTED'; response: AppsyncData<'extendAReservation'>['extendAReservation'] }
  | { type: 'SERVER'; error: Error };

type DialogScreenProps = StackScreenProps<
  { ExtendReservationDialog: ExtendReservationDialogRouteParams },
  'ExtendReservationDialog'
>;

export default function ExtendReservationDialog({ route, navigation }: DialogScreenProps) {
  const { compoundReservation } = route.params;
  const myProfileQuery = useMyProfileQuery();
  const [amountToExtendMinutes, setAmountToExtendMinutes] = useState<number>(60);
  const { activePaymentMethod } = useActivePayment();

  const reservationToExtend = compoundReservation.originals[compoundReservation.originals.length - 1];
  const podInformationQuery = useGetSpaceInfoWithPricing({
    input: {
      from: compoundReservation.to.toISOString(),
      to: addMinutes(compoundReservation.to, amountToExtendMinutes).toISOString(),
      spaceId: compoundReservation.spaceId,
    },
  });
  const costToExtend = podInformationQuery.data?.data?.estimatePriceAndHoldAmount?.price;

  const [minBookingMins, maxBookingMins] = useMemo(() => {
    if (!podInformationQuery.data?.data?.getPoisByID || !podInformationQuery.data?.data?.estimatePriceAndHoldAmount) {
      return [undefined, undefined];
    }
    /* Always can extend by 60 minutes, if can extend at all */
    const minBookingTimeMins = 60;
    let freeForMinutes = 0;
    const avail = podInformationQuery.data.data.getPoisByID.availabilityStatus;
    if (avail && avail.status === PodStatus.FREE && avail.nextFreeEnd) {
      const freeEndExclusive = new Date(avail.nextFreeEnd);
      freeForMinutes = differenceInMinutes(freeEndExclusive, compoundReservation.to);
    }
    return [minBookingTimeMins, freeForMinutes];
  }, [
    compoundReservation.to,
    podInformationQuery.data?.data?.estimatePriceAndHoldAmount,
    podInformationQuery.data?.data?.getPoisByID,
  ]);

  const { I18n, formatDateRange, formatDuration, formatCurrency, formatDurationBetween } = useI18n();
  const mp = useMixpanel();
  const stripe = useStripe();
  const { confirmPlatformPayPayment } = usePlatformPay();
  const [useCredits, setUseCredits] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const [preReservationId, setPreReservationId] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleExtendFail = useCallback(
    (reason: extendFail) => {
      mp?.getPeople().increment('Reservations extensions failed', 1);
      mp?.track('Reservation extend fail', flattenDict('reason', reason));
      if (reason.type === 'TIMESLOT') {
        setErrorMessage(I18n.t('extendReservation.extendReservationOverlap'));
      } else if (reason.type === 'PAYMENT') {
        setErrorMessage(reason.error.localizedMessage ?? reason.error.message);
      } else {
        setErrorMessage(I18n.t('payment.addErrorDescription'));
      }
    },
    [I18n, mp],
  );

  const creditBalance = myProfileQuery?.data?.data?.getMyProfile?.creditBalance;
  const creditCurrency = myProfileQuery?.data?.data?.getMyProfile?.currency?.toLowerCase();

  const currency = compoundReservation.spaceInfo.currency?.toLowerCase();

  const canUserPay = useMemo(() => {
    return (
      costToExtend !== undefined &&
      costToExtend !== null &&
      creditBalance !== undefined &&
      creditBalance !== null &&
      ((creditBalance >= costToExtend && useCredits) || !!activePaymentMethod)
    );
  }, [costToExtend, creditBalance, useCredits, activePaymentMethod]);

  const [moneyToSpend, creditsToSpend] = useMemo(() => {
    if (costToExtend && currency) {
      if (useCredits && creditCurrency === currency && creditBalance) {
        const crd = Math.min(creditBalance, costToExtend);
        return [costToExtend - crd, crd];
      }
      return [costToExtend, 0];
    }
    return [undefined, undefined];
  }, [creditBalance, creditCurrency, currency, costToExtend, useCredits]);

  useEffect(() => {
    const notEnoughCredits =
      creditBalance !== undefined &&
      creditBalance !== null &&
      costToExtend !== undefined &&
      costToExtend !== null &&
      creditBalance < costToExtend &&
      !activePaymentMethod;
    if (notEnoughCredits) {
      setErrorMessage(I18n.t('extendReservation.notEnoughCredit'));
    }
  }, [I18n, activePaymentMethod, creditBalance, costToExtend]);

  const cancelReservationMutation = useMutation(
    async (input: Parameters<typeof cancelReservation>) => {
      return cancelReservation(...input);
    },
    {
      onSuccess(data) {
        console.log('Cancel success', data);
      },
      onError(err) {
        console.log('Cancel fail', err);
      },
    },
  );

  const handlePaymentWithCard = useCallback(
    async (extendAReservation: Reservation) => {
      return stripe
        .confirmPayment(extendAReservation?.paymentSecret!, {
          paymentMethodType: 'Card',
          paymentMethodData: { paymentMethodId: (activePaymentMethod as CardPaymentMethod).stripeCard.id! },
        })
        .then((result) => {
          if (result.paymentIntent) {
            setTimeout(() => {
              invalidateReservationData(queryClient);
            }, 1000);

            trackExtendReservation(
              mp,
              extendAReservation,
              compoundReservation,
              result.paymentIntent.amount,
              extendAReservation!.credit!,
              result.paymentIntent.currency,
            );
            navigation.goBack();
          } else {
            throw result.error;
          }
        })
        .catch((error: StripeError<ConfirmPaymentError>) => {
          mp?.getPeople().increment('Payment failures', 1);
          mp?.track('Reservation extending payment failed', {
            ...flattenDict('reservation', extendAReservation),
            ...flattenDict('error', error),
          });
          Sentry.captureException(error);
          console.log('Payment with card fail');
          handleExtendFail({ type: 'PAYMENT', error });
        });
    },
    [stripe, activePaymentMethod, mp, compoundReservation, navigation, queryClient, handleExtendFail],
  );

  const handlePaymentWithGooglePay = useCallback(
    async (extendedReservation: Reservation): Promise<void> => {
      try {
        const { error: presentPlatformPaymentError } = await confirmPlatformPayPayment(
          extendedReservation?.paymentSecret!,
          {
            googlePay: {
              merchantName: 'Biketti Oy',
              merchantCountryCode: 'FI',
              testEnv: process.env.NODE_ENV === 'development',
              // TODO: Unhardcode. ISO 4217 alphabetic currency code.
              currencyCode: currency?.toUpperCase() ?? 'EUR',
            },
          },
        );
        if (presentPlatformPaymentError) {
          throw presentPlatformPaymentError;
        }

        setTimeout(() => {
          invalidateReservationData(queryClient);
        }, 1000);

        trackExtendReservation(
          mp,
          extendedReservation,
          compoundReservation,
          extendedReservation!.paymentAmountInCents!,
          extendedReservation!.credit!,
          extendedReservation!.currency!,
        );
        navigation.goBack();
      } catch (error) {
        mp?.getPeople().increment('Payment failures', 1);
        mp?.track('Reservation extending payment failed', {
          ...flattenDict('reservation', extendedReservation),
          ...flattenDict('error', error),
        });
        Sentry.captureException(error);
        handleExtendFail({ type: 'PAYMENT', error: error as StripeError<GooglePayError> });
      }
    },
    [confirmPlatformPayPayment, currency, mp, compoundReservation, navigation, queryClient, handleExtendFail],
  );

  const handleReservedWithoutPayment = useCallback(
    (extendAReservation: Reservation) => {
      setTimeout(() => {
        invalidateReservationData(queryClient);
      }, 1000);

      trackExtendReservation(
        mp,
        extendAReservation,
        compoundReservation,
        extendAReservation?.paymentAmountInCents ? extendAReservation.paymentAmountInCents / 100 : 0,
        extendAReservation?.credit ? extendAReservation.credit : 0,
        extendAReservation!.currency!,
      );
      navigation.goBack();
    },
    [mp, navigation, queryClient, compoundReservation],
  );
  const extendReservationMutation = useMutation(
    async (input: Parameters<typeof extendReservation>) => {
      return extendReservation(...input);
    },
    {
      async onSuccess(data) {
        if (data.data?.extendAReservation?.id !== reservationToExtend.id) {
          /* Backend returned a new id, going to need to cancel if things don't work out */
          setPreReservationId(data.data?.extendAReservation?.id);
        }

        switch (data.data?.extendAReservation?.status) {
          case ReservationStatus.CANCELLED:
            handleExtendFail({ type: 'CANCELLED', response: data.data!.extendAReservation! });
            break;

          case ReservationStatus.COMPLETED:
            handleExtendFail({ type: 'ENDED', response: data.data?.extendAReservation! });
            break;

          case ReservationStatus.PAYMENT_REQUIRED:
            // activePaymentMethod cannot be null, because user cannot click confirm button without active payment method
            if (!activePaymentMethod) {
              Alert.alert(I18n.t('payment.alert.noActivePaymentMethod'));
              return;
            }

            if (activePaymentMethod.paymentMethodType === PaymentMethodOption.CARD) {
              await handlePaymentWithCard(data.data.extendAReservation);
            } else {
              await handlePaymentWithGooglePay(data.data.extendAReservation);
            }

            break;
          case ReservationStatus.RESERVED:
            handleReservedWithoutPayment(data.data?.extendAReservation);
            break;
          default:
            handleExtendFail({ type: 'UNEXPECTED', response: data.data?.extendAReservation! });
            break;
        }
      },
      onError(error: any) {
        /* Could not do pre-payment-booking */
        const errorCode = error?.errors?.[0]?.message;
        if (errorCode === CANNOT_BOOK) {
          handleExtendFail({ type: 'TIMESLOT', error });
        } else {
          handleExtendFail({ type: 'SERVER', error });
        }
      },
    },
  );

  const newEndDate = addMinutes(compoundReservation.to, amountToExtendMinutes);
  const onConfirm = async () => {
    if (creditsToSpend !== undefined) {
      await extendReservationMutation.mutateAsync([
        {
          id: reservationToExtend.id,
          to: newEndDate.toISOString(),
          usedCredit: creditsToSpend * 100,
        },
      ]);
    }
  };

  const handleUserDismissed = useCallback(() => {
    if (preReservationId) {
      cancelReservationMutation.mutate([{ id: preReservationId }]);
    }
    navigation.goBack();
  }, [cancelReservationMutation, navigation, preReservationId]);

  const changeDuration = useCallback(
    (changeAmount: number) => {
      setAmountToExtendMinutes((prev) => {
        let newVal = prev + changeAmount;
        if (maxBookingMins !== undefined) newVal = Math.min(maxBookingMins, newVal);
        if (minBookingMins !== undefined) newVal = Math.max(minBookingMins, newVal);
        return newVal;
      });
    },
    [maxBookingMins, minBookingMins],
  );

  useEffect(() => {
    changeDuration(0);
  }, [changeDuration]);

  const onIncreaseDuration = useCallback(() => {
    changeDuration(+15);
  }, [changeDuration]);

  const onDecreaseDuration = useCallback(() => {
    changeDuration(-15);
  }, [changeDuration]);

  const isUnAvailable =
    minBookingMins !== undefined &&
    maxBookingMins !== undefined &&
    (amountToExtendMinutes < minBookingMins || amountToExtendMinutes > maxBookingMins);

  const time = `${formatDateRange(
    compoundReservation.from,
    newEndDate,
    'time',
    compoundReservation.timeZone,
  )} (${formatDurationBetween(compoundReservation.from, newEndDate)})`;

  return (
    <NavigationDialog
      title={I18n.t('extendReservation.extendConfirm')}
      description={''}
      iconName={'check'}
      actions={[
        {
          title: I18n.t('general.cancel'),
          onPress: handleUserDismissed,
          disable: extendReservationMutation.isLoading,
        },
        {
          title: I18n.t('general.confirm'),
          loading: extendReservationMutation.isLoading || podInformationQuery.isLoading,
          disable: extendReservationMutation.isLoading || podInformationQuery.isLoading || !canUserPay || isUnAvailable,
          onPress: () => onConfirm(),
        },
      ]}
    >
      {/* Large duration picker */}
      <View style={[ModalRowStyle, { flexDirection: 'row', justifyContent: 'space-between' }]}>
        <ArrowButton
          direction="left"
          onPress={onDecreaseDuration}
          disabled={minBookingMins === undefined || amountToExtendMinutes <= minBookingMins}
        />
        <Typography variant={'h5'}>{`+${formatDuration(amountToExtendMinutes, 'minutes')}`}</Typography>
        <ArrowButton
          direction="right"
          onPress={onIncreaseDuration}
          disabled={maxBookingMins === undefined || amountToExtendMinutes >= maxBookingMins}
        />
      </View>

      <ConfirmationRow title={time} iconName={'calendar'} />
      <ConfirmationRow title={formatCurrency(costToExtend, currency)} iconName={'wallet'} />

      <UseCreditsRow
        balance={creditBalance}
        currency={creditCurrency}
        value={useCredits}
        onValueChange={setUseCredits}
        disabled={creditCurrency !== currency}
        errorText={
          currency && creditCurrency !== currency?.toLowerCase()
            ? I18n.t('mapview.modalConfirmation.creditCurrencyNotMatching')
            : undefined
        }
      />

      {/* Amount due */}
      <AmountDueRow canUserMakeBooking={canUserPay} moneyToSpend={moneyToSpend} currency={currency} />

      <Container>
        <HelperText visible={!!errorMessage || isUnAvailable} type={'error'}>
          {errorMessage || ''}
        </HelperText>
      </Container>
    </NavigationDialog>
  );
}

import React, { FC } from 'react';
import { createStackNavigator, TransitionPresets } from '@react-navigation/stack';
import { useI18n } from '../context/I18nContext';
import { AppbarScreenOptions, DefaultModalOptions, DefaultScreenOptions } from '../components/NavigatorAppbar';
import { screenNames } from './screenNames';
import MyInfo from '../screens/MyInfo';
import UpdateName from '../screens/UpdateProfile/UpdateName';
import Organisation from '../screens/UpdateProfile/Organisation';
import UpdateOrganisation from '../screens/UpdateProfile/UpdateOrganisation';
import UpdateContact from '../screens/UpdateProfile/UpdateContact';
import RequestDiscount from '../screens/UpdateProfile/RequestDiscount';
import UpdateLanguage from '../screens/UpdateProfile/UpdateLanguage';
import UpdateNotifications from '../screens/UpdateProfile/UpdateNotifications';
import PaymentView from '../payment/screens/Payment';
import AddPaymentMethod from '../payment/screens/AddPaymentMethod';
import CardPaymentDetail, { CardPaymentDetailRouteParams } from '../payment/screens/CardPaymentDetail';
import WalletPaymentDetail, { WalletPaymentDetailRouteParams } from '../payment/screens/WalletPaymentDetail';
import AllRecentReceipts from '../payment/screens/AllRecentReceipts';
import CreditBalance from '../payment/screens/CreditBalance';
import ShareScreen from '../screens/Share';
import BookingView from '../screens/Booking';
import BookingDetail, { BookingDetailRouteParams } from '../screens/BookingDetail';
import QRScanScreen, { QRScreenRouteParamsType } from '../screens/QRScanScreen';
import EnterCodeManuallyScreen from '../screens/EnterCodeManuallyScreen';
import { ErrorDialog, ErrorDialogRouteParams } from '../screens/CommonDialogs';
import ExtendReservationDialog, {
  ExtendReservationDialogRouteParams,
} from '../payment/screens/ExtendReservationDialog';
import {
  CancelReservationDialog,
  CancelReservationDialogRouteParams,
} from '../payment/screens/CancelReservationDialog';
import EndReservationDialog, { EndReservationDialogRouteParams } from '../payment/screens/EndReservationDialog';
import AccountDeletionScreen from '../screens/AccountDeletion/AccountDeletionScreen';
import CofirmAccoutDeletionDialog from '../screens/AccountDeletion/ConfirmAccountDeletionDialog';
import { ConfirmLogoutDialog } from '../screens/ConfirmLogoutDialog';
import { ChooseStoreDialog } from '../screens/ChooseStoreDialog';
import { SpaceInfo, SpaceInfoRouteParams } from '../screens/SpaceInfo';

export type InformationStackRouteParams = {
  [screenNames.MyInfo]: undefined;
  [screenNames.Payments]: undefined;
  [screenNames.AddPaymentMethod]: undefined;
  [screenNames.CardDetail]: CardPaymentDetailRouteParams;
  [screenNames.WalletDetail]: WalletPaymentDetailRouteParams;
  [screenNames.AllRecentReceipts]: undefined;
  [screenNames.CreditBalance]: undefined;
  [screenNames.Share]: undefined;
  [screenNames.RequestDiscount]: undefined;
  [screenNames.Bookings]: undefined;
  [screenNames.BookingDetail]: BookingDetailRouteParams;
  [screenNames.SpaceInfo]: SpaceInfoRouteParams;
  [screenNames.UpdateName]: undefined;
  [screenNames.Organisation]: undefined;
  [screenNames.UpdateOrganisation]: undefined;
  [screenNames.UpdateContact]: undefined;
  [screenNames.UpdateLanguage]: undefined;
  [screenNames.UpdateNotifications]: undefined;
  [screenNames.QRScanScreen]: QRScreenRouteParamsType;
  [screenNames.EnterCodeManuallyScreen]: QRScreenRouteParamsType;
  [screenNames.ErrorDialog]: ErrorDialogRouteParams;
  [screenNames.ExtendReservationDialog]: ExtendReservationDialogRouteParams;
  [screenNames.CancelReservationDialog]: CancelReservationDialogRouteParams;
  [screenNames.EndReservationDialog]: EndReservationDialogRouteParams;
  [screenNames.AccountDeletion]: undefined;
  [screenNames.ConfirmAccountDeletionDialog]: undefined;
  [screenNames.ChooseStoreDialog]: undefined;
  [screenNames.ConfirmLogoutDialog]: undefined;
};

const Stack = createStackNavigator<InformationStackRouteParams>();

const InformationStackNavigator: FC = () => {
  const { I18n } = useI18n();

  return (
    <Stack.Navigator initialRouteName={screenNames.MyInfo} screenOptions={DefaultScreenOptions}>
      <Stack.Screen
        name={screenNames.MyInfo}
        component={MyInfo}
        options={{
          title: I18n.t('myinfo.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.Payments}
        component={PaymentView}
        options={{
          title: I18n.t('payment.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.AddPaymentMethod}
        component={AddPaymentMethod}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('payment.addACard'),
          ...TransitionPresets.ModalSlideFromBottomIOS,
        }}
      />
      <Stack.Screen
        name={screenNames.CardDetail}
        component={CardPaymentDetail}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('payment.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.WalletDetail}
        component={WalletPaymentDetail}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('payment.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.AllRecentReceipts}
        component={AllRecentReceipts}
        options={{
          title: I18n.t('payment.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.CreditBalance}
        component={CreditBalance}
        options={{
          title: I18n.t('creditBalance.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.Share}
        component={ShareScreen}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('share.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.RequestDiscount}
        component={RequestDiscount}
        options={{
          title: I18n.t('discountRequest.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.Bookings}
        component={BookingView}
        options={{
          title: I18n.t('bookings.headerTitle'),
        }}
      />

      <Stack.Screen
        name={screenNames.BookingDetail}
        component={BookingDetail}
        options={{
          title: I18n.t('bookings.headerTitle'),
        }}
      />

      <Stack.Screen
        name={screenNames.SpaceInfo}
        component={SpaceInfo}
        options={{
          ...DefaultScreenOptions,
          title: I18n.t('mapview.spaceInfo.headerTitle'),
        }}
      />

      <Stack.Screen
        name={screenNames.UpdateName}
        component={UpdateName}
        options={{
          title: I18n.t('updateName.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.Organisation}
        component={Organisation}
        options={{
          title: I18n.t('organisation.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.UpdateOrganisation}
        component={UpdateOrganisation}
        options={{
          title: I18n.t('organisation.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.UpdateContact}
        component={UpdateContact}
        options={{
          title: I18n.t('updateContact.headerTitle'),
        }}
      />

      <Stack.Screen
        name={screenNames.UpdateLanguage}
        component={UpdateLanguage}
        options={{
          title: I18n.t('updateLanguage.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.UpdateNotifications}
        component={UpdateNotifications}
        options={{
          title: I18n.t('updateNotifications.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.QRScanScreen}
        component={QRScanScreen}
        options={{
          ...AppbarScreenOptions,
          title: I18n.t('mapview.scanQr.headerTitle'),
        }}
      />
      <Stack.Screen
        name={screenNames.EnterCodeManuallyScreen}
        component={EnterCodeManuallyScreen}
        options={DefaultScreenOptions}
      />
      <Stack.Screen
        name={screenNames.ExtendReservationDialog}
        component={ExtendReservationDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen
        name={screenNames.EndReservationDialog}
        component={EndReservationDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen
        name={screenNames.CancelReservationDialog}
        component={CancelReservationDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen
        name={screenNames.AccountDeletion}
        component={AccountDeletionScreen}
        options={{
          title: I18n.t('myinfo.items.deleteAccount'),
        }}
      />
      <Stack.Screen
        name={screenNames.ConfirmAccountDeletionDialog}
        component={CofirmAccoutDeletionDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen
        name={screenNames.ConfirmLogoutDialog}
        component={ConfirmLogoutDialog}
        options={DefaultModalOptions}
      />
      <Stack.Screen name={screenNames.ErrorDialog} component={ErrorDialog} options={DefaultModalOptions} />
      <Stack.Screen name={screenNames.ChooseStoreDialog} component={ChooseStoreDialog} options={DefaultModalOptions} />
    </Stack.Navigator>
  );
};

export default InformationStackNavigator;

import React, { PropsWithChildren, useCallback } from 'react';
import { GestureResponderEvent } from 'react-native';
import GradientButton, { GradientButtonProps } from './GradientButton';
import { ResourceSignalStrength } from '../types/misc.types';
// import { UiIconType } from './SimpleListIcon';

// function getSignalStrengthIcons(signalStrength?: ResourceSignalStrength): UiIconType[] {
//   switch (signalStrength) {
//     case ResourceSignalStrength.STRONG:
//       return [
//         { set: 'material', name: 'bluetooth' },
//         { set: 'material-community', name: 'signal-cellular-3' },
//       ];
//     case ResourceSignalStrength.MODERATE:
//       return [
//         { set: 'material', name: 'bluetooth' },
//         { set: 'material-community', name: 'signal-cellular-2' },
//       ];
//     case ResourceSignalStrength.WEAK:
//       return [
//         { set: 'material', name: 'bluetooth' },
//         { set: 'material-community', name: 'signal-cellular-1' },
//       ];
//     default:
//       return [
//         { set: 'material', name: 'bluetooth-disabled' },
//         { set: 'material-community', name: 'signal-cellular-outline' },
//       ];
//   }
// }

export type ResourceLockButtonProps = GradientButtonProps & {
  resourceId: string | undefined | null;
  signalStrength?: ResourceSignalStrength;
  accessResource: (resourceId: string) => Promise<unknown>;
};

export function ResourceLockButton({
  onPress,
  children,
  resourceId,
  signalStrength,
  loading,
  accessResource,
  ...other
}: PropsWithChildren<ResourceLockButtonProps>) {
  const handlePress = useCallback(
    (e: GestureResponderEvent) => {
      if (resourceId) {
        accessResource(resourceId);
      }
      if (onPress) onPress(e);
    },
    [accessResource, onPress, resourceId],
  );

  return (
    <GradientButton
      onPress={handlePress}
      loading={loading}
      icon={'lock'}
      // rightIcons={getSignalStrengthIcons(signalStrength)}
      fixedHeight={false}
      {...other}
    >
      {children}
    </GradientButton>
  );
}

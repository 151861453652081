import React from 'react';
import { Dialog, Modal, Portal, Surface, useTheme } from 'react-native-paper';
import { FlatList, GestureResponderEvent, useWindowDimensions } from 'react-native';
import { useI18n } from '../../context/I18nContext';
import SummaryHeader from '../SummaryHeader';
import { ModalButtonWrapper } from './TextModal';
import { Resource } from '../../types/appsync-types';
import { ResourceLockButton } from '../ResourceLockButton';
import { BlurOverlay } from '../BlurOverlay';
import { useBitwards } from '../../bitwards/BitwardsContext';

type SelectResourceModalProps = {
  resources: (Resource | null | undefined)[];
  isVisible: boolean;
  disabled?: boolean;
  loading?: boolean;
  onPress?: (e: GestureResponderEvent) => void;
  onDismiss: () => void;
};

export function SelectResourceModal({
  resources,
  isVisible,
  disabled,
  loading = false,
  onPress,
  onDismiss,
}: SelectResourceModalProps) {
  const { I18n } = useI18n();
  const { dark } = useTheme();
  const { width, height } = useWindowDimensions();
  const { accessResource, loading: bitwardsLoading } = useBitwards();

  const handlePress = (e: GestureResponderEvent) => {
    onPress?.(e);
    onDismiss();
  };

  // const resourcesWithSignalStrength = resources
  //   .filter((resource) => !!resource)
  //   .map((resource) => {
  //     const matchingVisibleResource = visibleResources.find(
  //       (visibleResource) => visibleResource.id === resource?.resourceId,
  //     );
  //     return {
  //       ...resource,
  //       signalStrength: rssiToSignalStrength(matchingVisibleResource?.rssi),
  //     };
  //   });

  return (
    <Portal>
      {isVisible && (
        <BlurOverlay>
          <Modal
            dismissable
            visible={isVisible}
            onDismiss={onDismiss}
            contentContainerStyle={{
              borderRadius: 5,
              maxWidth: Math.min(380, 0.9 * width),
              maxHeight: Math.min(660, 0.8 * height),
              alignSelf: 'center',
            }}
          >
            <Surface style={{ height: '100%' }}>
              <SummaryHeader title={I18n.t('selectResourceModal.title')} style={{ paddingHorizontal: 16 }} />
              <FlatList
                data={resources.filter((resource) => !!resource)}
                scrollEnabled={true}
                fadingEdgeLength={30}
                indicatorStyle={dark ? 'white' : 'black'}
                renderItem={({ item }) => {
                  return (
                    <ResourceLockButton
                      resourceId={item.resourceId}
                      // signalStrength={item.signalStrength}
                      loading={loading || bitwardsLoading}
                      accessResource={accessResource}
                      onPress={handlePress}
                      style={{ paddingHorizontal: 16, marginVertical: 8 }}
                      disabled={disabled}
                      contentCentered={false}
                    >
                      {item.title}
                    </ResourceLockButton>
                  );
                }}
              />
              <Dialog.Actions style={{ flexGrow: 0, paddingTop: 24 }}>
                <ModalButtonWrapper key={'cancel'} title={I18n.t('general.cancel')} onPress={onDismiss} />
              </Dialog.Actions>
            </Surface>
          </Modal>
        </BlurOverlay>
      )}
    </Portal>
  );
}

import React, { useCallback, useMemo } from 'react';
import { CompositeScreenProps, useNavigation } from '@react-navigation/native';
import { StackScreenProps } from '@react-navigation/stack';
import { addMinutes } from 'date-fns';
import { useI18n } from '../../context/I18nContext';
import ScrollablePage from '../../components/ui/ScrollablePage';
import { drawerScreens, screenNames } from '../../navigators/screenNames';
import Typography from '../../components/Typography';
import Container from '../../components/Container';
import { AppDrawerNavigationProp, AppDrawerScreenProp } from '../../components/DrawerMenuContent';
import { LocationStackRouteParams } from '../../navigators/LocationsStackNavigator';
import { useNowMinutes } from '../../hooks/useNow';
import { ZoomPointAction } from '../../utils/urlHandler';
import { BUILDING_SELECT_ZOOM } from '../../utils/mapViewUtils';
import { useBuildingsForRegion } from '../../apis/venueApis';
import { AuthState, useAuth } from '../../context/AuthContext';
import { DEFAULT_DURATION_STEP_MINS } from '../../context/MapViewFilterContext';
import { listIconGenerator } from '../../components/SimpleListIcon';
import { ListItemRow } from '../../components/ListItemRow';

function VenueItem(props: { title: string; lat: number; lon: number; available: number; total: number }) {
  const { title, lat, lon, available, total } = props;
  const { I18n } = useI18n();
  const navigation = useNavigation<AppDrawerNavigationProp>();

  const onPress = useCallback(() => {
    const params: { action: ZoomPointAction } = {
      action: {
        type: 'ZOOM',
        lat,
        lon,
        zoom: BUILDING_SELECT_ZOOM,
      },
    };
    navigation.navigate({
      name: drawerScreens.MapStack,
      params: {
        screen: screenNames.Map,
        params,
      },
      merge: true, // This makes the parent screen, i.e. mapview get these new params
    });
  }, [lat, lon, navigation]);

  return (
    <ListItemRow
      title={title}
      description={I18n.t('locations.region.spacesAvailable', {
        available,
        total,
        locations: total === 1 ? I18n.t('locations.region.location') : I18n.t('locations.region.locations'),
      })}
      right={listIconGenerator('map')}
      onPress={onPress}
    />
  );
}

export type RegionVenuesRouteParams = {
  regionTranslationId: string;
  regionDefaultName: string;
  regionId: string;
};

type Props = CompositeScreenProps<
  StackScreenProps<LocationStackRouteParams, 'RegionVenuesScreen'>,
  AppDrawerScreenProp
>;

export const RegionVenuesScreen: React.FC<Props> = ({ route }) => {
  const { I18n } = useI18n();
  const { state: authState } = useAuth();
  const now = useNowMinutes();

  const buildingsQuery = useBuildingsForRegion(authState === AuthState.AUTHENTICATED, route.params.regionId, {
    from: now.toISOString(),
    to: addMinutes(now, DEFAULT_DURATION_STEP_MINS).toISOString(),
  });

  const venues = buildingsQuery.data?.data?.getBuildingsByRegionId;

  const regionName = I18n.t(`locations.regions.${route.params.regionTranslationId}`, {
    defaultValue: route.params.regionDefaultName,
  });

  const translatedAndSortedVenues = useMemo(() => {
    /* Translate and sort venues */
    return venues
      ?.map((venueInfo) => {
        // Some venues contains soft hyphen due to map clustering.
        // We may need to translate the venue i.e. Päärautatieasema and getting the translation doesn't work if the string contains soft hyphen
        const title = venueInfo?.title?.replace(/\u00AD/g, '');
        return {
          ...venueInfo,
          title: I18n.t(`locations.venues.${venueInfo?.buildingRef ?? ''}`, {
            defaultValue: venueInfo?.title ? I18n.t(`locations.venues.${title}`, { defaultValue: title }) : '<missing>',
          }),
        };
      })
      .sort((a, b) => a.title.localeCompare(b.title, I18n.locale));
  }, [I18n, venues]);

  return (
    <ScrollablePage
      summary={{
        title: I18n.t('locations.region.summaryTitle', {
          region: regionName,
        }),
        description: I18n.t('locations.region.summaryDescription'),
        icon: 'pin',
      }}
    >
      {translatedAndSortedVenues?.map((venueInfo) =>
        venueInfo ? (
          <VenueItem
            lat={venueInfo.lat!}
            lon={venueInfo.lon!}
            key={venueInfo.buildingRef}
            title={venueInfo.title}
            available={venueInfo.buildingStatus?.bookable ?? 0}
            total={venueInfo.buildingStatus?.total ?? 0}
          />
        ) : null,
      )}

      {buildingsQuery.isIdle && (!translatedAndSortedVenues || translatedAndSortedVenues.length === 0) ? (
        <Container>
          <Typography variant="body1">{I18n.t('locations.region.noVenues')}</Typography>
        </Container>
      ) : null}
    </ScrollablePage>
  );
};

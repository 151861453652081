import React, { useContext, useEffect, useMemo, useState } from 'react';
import Constants from 'expo-constants';
import { View, StyleSheet, Image, ScrollView, PixelRatio, Platform } from 'react-native';
import { Banner } from 'react-native-paper';
import { NavigationContext } from '@react-navigation/native';
import Typography from '../../components/Typography';
import {
  NOTIFICATION_LIFE_TIME_MS,
  RESERVED_NOTIFICATION_KEYS,
  useMapViewBanner,
} from '../../context/MapViewBannerContext';
import { useExtractedReservationData } from '../../apis/reservationApis';
import { useBitwards } from '../../bitwards/BitwardsContext';
import { flattenDict } from '../../utils/helpers';
import { setNotificationListener } from '../../utils/pushNotifications';
import { screenNames } from '../../navigators/screenNames';
import { useI18n } from '../../context/I18nContext';
import useScreenSize from '../../hooks/useScreenSize';
import { useNowMinutes } from '../../hooks/useNow';
import { useMixpanel } from '../../mixpanel/MixpanelContext';
import Images from '../../../assets/images';
import { SelectResourceModal } from '../../components/modal/SelectResourceModal';
import { Resource, ReservationType } from '../../types/appsync-types';

type NotificationBannerProps = {
  onBannerHeightChange?: (visibleHeight: number) => void;
};

export function NotificationBanner({ onBannerHeightChange }: NotificationBannerProps) {
  const { windowHeight } = useScreenSize();
  const mp = useMixpanel();
  const now = useNowMinutes();
  const { currentNotificationPayload, removeNotificationWithKey, displayNotification } = useMapViewBanner();
  const { I18n, formatDurationBetween } = useI18n();
  const navigation = useContext(NavigationContext);
  const { ongoingReservations } = useExtractedReservationData();
  const { accessResource, loading } = useBitwards();
  const [modalResources, setModalResources] = useState<(Resource | undefined | null)[] | null>(null);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (currentNotificationPayload) {
      setTimeout(() => {
        displayNotification(null);
      }, NOTIFICATION_LIFE_TIME_MS);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [currentNotificationPayload, displayNotification]);

  useEffect(() => {
    if (ongoingReservations && ongoingReservations.length > 0) {
      removeNotificationWithKey(RESERVED_NOTIFICATION_KEYS.BOOKING_SUCCEED);
    }
  }, [ongoingReservations, removeNotificationWithKey]);

  useEffect(() => {
    // get the notification data when notification is received
    setNotificationListener((notification: any) => {
      // Note that the notification object structure is different from Android and IOS
      const maybeReservationId = notification?._data?.data?.jsonBody?.reservationId;
      if (maybeReservationId) {
        navigation?.navigate('map', {
          screen: screenNames.BookingDetail,
          params: {
            bookingId: maybeReservationId,
          },
        });
      }

      // TODO: Add news related push note navigation here
    });
  }, [navigation]);

  const content = (() => {
    return (
      <View>
        <Typography variant={'sub1'}>{currentNotificationPayload?.helperContent}</Typography>
        <Typography variant={'h6'}>{currentNotificationPayload?.mainContent}</Typography>
      </View>
    );
  })();

  const actions = useMemo(() => {
    const ret = [];
    if (!Constants.manifest?.extra?.disableBitwards) {
      ret.push({
        label: loading ? I18n.t('mapview.banner.unlocking') : I18n.t('mapview.banner.unlock'),
        onPress: () => {
          if (!loading) {
            const reservation = ongoingReservations[0];
            mp?.track('Unlock attempt from banner', flattenDict('space', reservation!.spaceInfo));
            if (reservation.spaceInfo.resources?.length === 1 && reservation.spaceInfo.resources[0]?.resourceId) {
              accessResource(reservation.spaceInfo.resources[0]?.resourceId);
            } else if (reservation.spaceInfo.resources && reservation.spaceInfo.resources.length > 1) {
              setModalResources(reservation.spaceInfo.resources);
            }
          }
        },
      });
    }
    ret.push({
      label: I18n.t('mapview.banner.viewDetails'),
      onPress: () => {
        navigation?.navigate(screenNames.BookingDetail, {
          bookingId: ongoingReservations[0]!.initialId,
        });
      },
    });

    return ret;
  }, [I18n, accessResource, loading, mp, navigation, ongoingReservations]);

  return (
    <ScrollView
      style={[
        styles.wrapper,
        { top: Platform.OS === 'ios' ? 56 : 0 }, // 56 is the height of the MapFilterAppBar
        PixelRatio.getFontScale() > 1.5 ? { maxHeight: windowHeight / 3 } : {},
      ]}
      onLayout={(event) => {
        onBannerHeightChange?.(event.nativeEvent.layout.height);
      }}
      bounces={false}
    >
      {/* To avoid race condition in showing current reservation banner, it should be handled in
          a separate instance than normal notification banner. This banner will take lower priority
          than normal banner and will be hidden when there is an active notification payload
       */}
      {ongoingReservations &&
      ongoingReservations.length > 0 &&
      ongoingReservations[0].type !== ReservationType.INVOICED ? (
        <Banner
          actions={actions}
          visible={!currentNotificationPayload}
          icon={({ size }) => (
            <Image
              source={
                Images[
                  (ongoingReservations[0]?.spaceInfo?.metadata?.imageUrl as 'BIK500x500px' | 'CYKLON500x500px') ||
                    'BIK500x500px'
                ] || Images.BIK500x500px
              }
              style={{ width: size, height: size }}
            />
          )}
        >
          {
            /* Cast to any, as Banner doesn't support non text children accorind to TS */
            (
              <View>
                <Typography variant={'sub1'}>{I18n.t('mapview.banner.ongoing')}</Typography>
                <Typography variant={'h6'}>
                  {formatDurationBetween(now, new Date(ongoingReservations[0]?.to!))}
                </Typography>
              </View>
            ) as any
          }
        </Banner>
      ) : null}
      <Banner
        actions={currentNotificationPayload?.actions ?? []}
        visible={!!currentNotificationPayload}
        icon={currentNotificationPayload?.icon}
      >
        {/* Banner children TS definition only allow text, even though normal react component works
            This is a workaround and in near future there is no reliable and not hacky fix
         */}
        {content as any}
      </Banner>
      {!!modalResources && (
        <SelectResourceModal
          resources={modalResources}
          isVisible={!!modalResources}
          loading={loading}
          onDismiss={() => setModalResources(null)}
        />
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
});

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Constants from 'expo-constants';
import { View, Insets, StyleSheet, Platform } from 'react-native';
import { useTheme, Snackbar } from 'react-native-paper';
import { useQuery } from 'react-query';
import { CompositeScreenProps, useIsFocused } from '@react-navigation/native';
import { addMinutes, format } from 'date-fns';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { StackScreenProps } from '@react-navigation/stack';
import { getTimeZone } from 'react-native-localize';
import { useI18n } from '../context/I18nContext';
import SmartMap, { FocusLocation, MapCamera } from '../components/map/SmartMap';
import MarkerCluster, {
  MarkerClickHandler,
  MarkerGJFeature,
  VisibilityMode,
} from '../components/map/content/MarkerCluster';
import PodInformationCard from './MapView/PodInformationCard';
import { flattenDict } from '../utils/helpers';
import { useMapViewFilter } from '../context/MapViewFilterContext';
import { useLocation, LocationPermissionStatus } from '../context/UserLocationContext';
import {
  convertGetBuildingResultToGeoJson,
  GeoJsonWithReservation,
  GeoJsonWithVenueAvailability,
} from '../utils/geojsonConverter';
import { Pod } from '../types/misc.types';
import { NotificationBanner } from './MapView/NotificationBanner';
import {
  useExtractedReservationData,
  useSubscribeToBuildingAvailability,
  useSubscribeToOwnReservationChange,
  useSubscribeToReservationChange,
} from '../apis/reservationApis';
import {
  AvailabilityMarkerVars,
  generateAvailabilityMarker,
  GJClusterProps,
  podAvailabilityClusterProperties,
  venueAvailabilityClusterProperties,
} from '../utils/mapAvailabilityMarkerGenerator';
import { extractAllPods, headingAccuracyToDeg, POD_SELECT_ZOOM, BUILDING_SELECT_ZOOM } from '../utils/mapViewUtils';
import {
  UnlockAction,
  SelectSpaceAction,
  ZoomPointAction,
  ShowVenueAction,
  CheckNotificationPermissionAction,
} from '../utils/urlHandler';
import { FloorBadge } from '../components/ui/FloorSwitcher';
import { QRCodeButton } from '../components/ui/QRCodeButton';
import { useModal } from '../context/ModalContext';
import UpdateModal, { shouldShowUpdateModal, whatsNewVersion } from '../components/modal/UpdateModal';
import { getData, InternalStorageItemKey, storeData } from '../utils/internalStorage';
import { getBuildingsWithinRadius } from '../apis/mapApis';
import { MapStackRouteParams } from '../navigators/MapStackNavigator';
import { AppDrawerScreenProp } from '../components/DrawerMenuContent';
import { useBitwards } from '../bitwards/BitwardsContext';
import { useMixpanel } from '../mixpanel/MixpanelContext';
import {
  Building,
  Maybe,
  PodStatus,
  ReservationStatus,
  Resource,
  SubscriptionOnUpdateReservationArgs,
  TimeRangeInput,
} from '../types/appsync-types';
import { EnrichedMapEvent } from '../components/map/content/helpers/ClickMonitor';
import { NotificationPermissionDialog } from '../components/modal/NotificationPermissionDialog';
import { initAppPushNotification, requestNotificationPermission } from '../utils/pushNotifications';
import { SelectResourceModal } from '../components/modal/SelectResourceModal';

export type MapViewRouteParams = {
  action?: SelectSpaceAction | UnlockAction | ShowVenueAction | ZoomPointAction | CheckNotificationPermissionAction;
};

type PoisWithReservationData = {
  venues: MarkerGJFeature<GeoJsonWithVenueAvailability>[];
  pois: MarkerGJFeature<GeoJsonWithReservation>[];
};

type Props = CompositeScreenProps<StackScreenProps<MapStackRouteParams, 'MapView'>, AppDrawerScreenProp>;

export const MapView: React.FC<Props> = ({ navigation, route }) => {
  const { colors } = useTheme();
  const { I18n, currentLocale } = useI18n();
  const mp = useMixpanel();
  const isFocused = useIsFocused();
  const [selectedPod, setSelectedPod] = useState<Pod | null>(null);
  const [focusLocation, setFocusLocation] = useState<FocusLocation | undefined>(undefined);
  const { ongoingReservations, isLoading: isLoadingReservations } = useExtractedReservationData();
  const [currentBuilding, setCurrentBuilding] = useState<string | undefined>();
  const { filter, onTimeZoneChange, timeZone } = useMapViewFilter();
  const { accessResource, loading } = useBitwards();
  const { openModal, closeModal } = useModal();
  const [mapInsets, setMapInsets] = useState<Insets>({ bottom: 0 });
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const { requestLocationPermissionAsync, location, heading, locationPermissionStatus } = useLocation();
  const safeInsets = useSafeAreaInsets();
  const [notificationModalIsVisible, setNoficationModalIsVisible] = useState(false);
  const [modalResources, setModalResources] = useState<(Resource | undefined | null)[] | null>(null);

  const selectPod = (pod: Pod | null) => {
    if (pod?.availabilityStatus?.status === PodStatus.COMING_SOON) {
      setSelectedPod(null);
    } else {
      setSelectedPod(pod);
    }
  };

  useEffect(() => {
    initAppPushNotification();
  }, []);

  useEffect(() => {
    if (route.params?.action?.type === 'CHECK-PERMISSION' && Platform.OS !== 'web') {
      getData<boolean>(InternalStorageItemKey.DONT_SHOW_NOTIFICATION_DIALOG).then((res) => {
        if (!res) {
          setNoficationModalIsVisible(true);
        }
      });

      navigation.setParams({ action: undefined });
    }
  }, [route.params?.action, navigation]);

  const [markerVars, setMarkerVars] = useState<AvailabilityMarkerVars>(() => {
    return {
      podBookedByMe: I18n.t('mapview.markers.pod.bookedByMe'),
      podAvailable: I18n.t('mapview.markers.pod.available'),
      podAvailableIndefinitely: I18n.t('mapview.markers.pod.availableIndefinitely'),
      podUnavailable: I18n.t('mapview.markers.pod.unavailable'),
      podComingSoon: I18n.t('mapview.markers.pod.comingSoon'),
      venueWithAvailableHours: I18n.t('mapview.markers.venue.someAvailableHours'),
      venueWithAvailableMinutes: I18n.t('mapview.markers.venue.someAvailableMinutes'),
      venueWithAvailableIndefinitely: I18n.t('mapview.markers.venue.someAvailableIndefinitely'),
      venueWithoutAvailable: I18n.t('mapview.markers.venue.noneAvailable'),
      venueNoneExist: I18n.t('mapview.markers.venue.noneExist'),
      clusterSomeAvailableHours: I18n.t('mapview.markers.clusterSomeAvailableHours'),
      clusterSomeAvailableMinutes: I18n.t('mapview.markers.clusterSomeAvailableMinutes'),
      clusterSomeAvailableIndefinitely: I18n.t('mapview.markers.clusterSomeAvailableIndefinitely'),
      clusterNoneAvailable: I18n.t('mapview.markers.clusterNoneAvailable'),
      clusterNoneExist: I18n.t('mapview.markers.clusterNoneExist'),
      location: I18n.t('mapview.markers.location'),
      locations: I18n.t('mapview.markers.locations'),
      viewTimestampS: filter.date.getTime() / 1000,
      selectedId: undefined,
    };
  });

  /* Update start time to map markers */
  useEffect(() => {
    setMarkerVars((oldVars) => {
      return {
        ...oldVars,
        viewTimestampS: filter.date.getTime() / 1000,
      };
    });
  }, [filter.date]);

  /* Update language to map markers */
  useEffect(() => {
    setMarkerVars((oldVars) => {
      return {
        ...oldVars,
        podBookedByMe: I18n.t('mapview.markers.pod.bookedByMe'),
        podAvailable: I18n.t('mapview.markers.pod.available'),
        podAvailableIndefinitely: I18n.t('mapview.markers.pod.availableIndefinitely'),
        podUnavailable: I18n.t('mapview.markers.pod.unavailable'),
        podComingSoon: I18n.t('mapview.markers.pod.comingSoon'),
        venueWithAvailableHours: I18n.t('mapview.markers.venue.someAvailableHours'),
        venueWithAvailableMinutes: I18n.t('mapview.markers.venue.someAvailableMinutes'),
        venueWithAvailableIndefinitely: I18n.t('mapview.markers.venue.someAvailableIndefinitely'),
        venueWithoutAvailable: I18n.t('mapview.markers.venue.noneAvailable'),
        clusterSomeAvailableHours: I18n.t('mapview.markers.clusterSomeAvailableHours'),
        clusterSomeAvailableMinutes: I18n.t('mapview.markers.clusterSomeAvailableMinutes'),
        clusterSomeAvailableIndefinitely: I18n.t('mapview.markers.clusterSomeAvailableIndefinitely'),
        clusterNoneAvailable: I18n.t('mapview.markers.clusterNoneAvailable'),
        clusterNoneExist: I18n.t('mapview.markers.clusterNoneExist'),
      };
    });
  }, [I18n]);

  /* GraphQL filter for the given time and duration */
  const currentTimeQueryFilter: TimeRangeInput = useMemo(() => {
    return {
      from: filter.date.toISOString(),
      to: addMinutes(filter.date, 15).toISOString(),
    };
  }, [filter.date]);

  const currentSubscriptionDayRange: SubscriptionOnUpdateReservationArgs = useMemo(() => {
    return {
      reservedDay: format(filter.date, 'yyyy-MM-dd'),
    };
  }, [filter.date]);

  const buildingData = useQuery(
    [getBuildingsWithinRadius.id, ...Object.values(currentTimeQueryFilter)],
    () => {
      return getBuildingsWithinRadius({ poiStatusFilter: currentTimeQueryFilter });
    },
    { keepPreviousData: true },
  );
  const podData = useMemo(() => extractAllPods(buildingData.data?.data), [buildingData.data?.data]);

  // TODO: This own reservation change subscription should not be used on not-logged-in users
  useSubscribeToOwnReservationChange(currentSubscriptionDayRange, currentTimeQueryFilter);
  useSubscribeToReservationChange(currentSubscriptionDayRange, currentTimeQueryFilter);
  useSubscribeToBuildingAvailability(currentSubscriptionDayRange, currentTimeQueryFilter);

  const [mapPoisWithReservationData, setMapPoisWithReservationData] = useState<PoisWithReservationData>({
    pois: [],
    venues: [],
  });

  const handleShowUpdateModal = useCallback(async () => {
    if (await shouldShowUpdateModal(currentLocale)) {
      openModal({
        content: (
          <UpdateModal
            onDismiss={() => {
              closeModal();
            }}
            locale={currentLocale}
          />
        ),
        onClose: () => {
          storeData(InternalStorageItemKey.WHATS_NEW_VERSION, whatsNewVersion);
        },
      });
    }
  }, [openModal, closeModal, currentLocale]);

  useEffect(() => {
    handleShowUpdateModal();
  }, [handleShowUpdateModal]);

  /* Mixpanel analytics */
  useEffect(() => {
    if (selectedPod) {
      mp?.track('Pod selected', flattenDict('space', selectedPod));
    }
  }, [selectedPod, mp]);

  /* Update pois when building data changes */
  useEffect(() => {
    const convertBuildingName = (origTitle: string | undefined | null) => {
      if (origTitle === undefined || origTitle === null) {
        return '?';
      }
      const r = I18n.t(`locations.venues.${origTitle}`, { defaultValue: origTitle });
      // console.log(`Translated ${origTitle} --> ${r}`);
      return r;
    };

    if (!buildingData.isFetching && buildingData.data?.data) {
      setMapPoisWithReservationData(convertGetBuildingResultToGeoJson(buildingData.data.data, convertBuildingName));
    }
  }, [I18n, buildingData.data?.data, buildingData.isFetching]);

  /* Update selected marker when selectedPod changes */
  useEffect(() => {
    let podGJ: MarkerGJFeature<GeoJsonWithReservation> | undefined;
    if (selectedPod) {
      podGJ = mapPoisWithReservationData.pois.find((p) => p.properties.localRef === selectedPod.localRef);
    }
    setMarkerVars((prev) => {
      return {
        ...prev,
        selectedId: podGJ?.id,
      };
    });
  }, [selectedPod, mapPoisWithReservationData.pois]);

  /**
   * Select space and jump to it based on route
   */
  useEffect(() => {
    let pod: Pod | undefined;
    if (route.params?.action && route.params.action.type !== 'CHECK-PERMISSION') {
      const action: UnlockAction | SelectSpaceAction | ZoomPointAction | ShowVenueAction = route.params?.action;
      if (action.type === 'VENUE') {
        // Find lat lon from building ref
        if (buildingData.isFetching) {
          return;
        }
        const building = buildingData.data?.data?.getBuildingsWithinRadius?.find((e) => e?.buildingRef === action.id);
        if (building) {
          setFocusLocation({
            lat: building.lat!,
            lng: building.lon!,
            zoom: BUILDING_SELECT_ZOOM,
          });
        }
        /* Finally clear action params */
        navigation.setParams({ action: undefined });
      } else if (action.type === 'ZOOM') {
        /**
         * Close currently open PodInformationCard if there's any. Zoom action is only used when user selects
         * a venue from location listing, so in that case its likely that user doesn't care about the currently
         * selected pod anymore.
         */
        selectPod(null);

        setFocusLocation({
          lat: action.lat,
          lng: action.lon,
          floorIndex: action.layerIndex,
          buildingRef: action.buildingRef,
          zoom: action.zoom,
        });
        /* Finally clear action params */
        navigation.setParams({ action: undefined });
      } else {
        // Let's fid the pod first:
        pod = podData.find((e) => e.localRef === action.id);
        if (pod) {
          /* Select the pod, regardless of action */
          selectPod(pod);
          setFocusLocation({
            lat: Number(pod.lat),
            lng: Number(pod.lon),
            floorIndex: Number(pod.layerIndex),
            buildingRef: pod.buildingRef!,
            zoom: POD_SELECT_ZOOM,
          });

          if (action.type === 'UNLOCK' && !isLoadingReservations) {
            const ongoingForPod = ongoingReservations.find((res) => res?.spaceInfo.localRef === action.id);
            if (
              ongoingForPod?.status === ReservationStatus.RESERVED &&
              ongoingForPod.spaceInfo.resources &&
              ongoingForPod.spaceInfo.resources.length > 0 &&
              !Constants.manifest?.extra?.disableBitwards &&
              !loading
            ) {
              if (ongoingForPod.spaceInfo.resources.length === 1 && ongoingForPod.spaceInfo.resources[0]?.resourceId) {
                accessResource(ongoingForPod.spaceInfo.resources[0].resourceId);
              } else if (ongoingForPod.spaceInfo.resources.length > 1) {
                setModalResources(ongoingForPod.spaceInfo.resources);
              }
            }
            /* Finally clear action params */
            navigation.setParams({ action: undefined });
          } else {
            /* Finally clear action params */
            navigation.setParams({ action: undefined });
          }
        }
      }
    }
  }, [
    podData,
    navigation,
    route.params,
    buildingData.isFetching,
    ongoingReservations,
    accessResource,
    buildingData.data?.data?.getBuildingsWithinRadius,
    isLoadingReservations,
    loading,
  ]);

  /* Request location permission */
  useEffect(() => {
    if (locationPermissionStatus === LocationPermissionStatus.UNDETERMINED && requestLocationPermissionAsync) {
      requestLocationPermissionAsync();
    }
  }, [locationPermissionStatus, requestLocationPermissionAsync]);

  /* If location permission at some point goes to denied, we show the snackbar. */
  useEffect(() => {
    if (locationPermissionStatus === LocationPermissionStatus.DENIED) {
      setSnackbarVisible(true);
    } else if (locationPermissionStatus === LocationPermissionStatus.GRANTED) {
      setSnackbarVisible(false);
    }
  }, [locationPermissionStatus]);

  useEffect(() => {
    setMapInsets((prev) => {
      return { ...prev, left: safeInsets.left, right: safeInsets.right, bottom: safeInsets.bottom };
    });
  }, [safeInsets]);

  const bluedotLocation = useMemo(() => {
    if (location) {
      return {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
        building: null,
        floor: null,
      };
    }
  }, [location]);

  const bluedotAccuracy = useMemo<number | undefined>(() => {
    return location?.coords.accuracy ?? undefined;
  }, [location]);

  const bluedotHeading = useMemo<{ accuracyDeg: number; valueDeg: number } | undefined>(() => {
    if (heading) {
      return {
        accuracyDeg: headingAccuracyToDeg(heading.accuracy),
        valueDeg: heading.trueHeading,
      };
    }
  }, [heading]);

  const onMapClick = useCallback((evt: EnrichedMapEvent) => {
    const poi = evt.mapFeatures?.find((f) => f.layer.id === 'POI Extrude' && f.properties?.point !== undefined);
    if (poi) {
      const centerPoint = JSON.parse(poi.properties!.point!);
      setFocusLocation({
        lat: centerPoint.coordinates[1],
        lng: centerPoint.coordinates[0],
        animate: true,
      });
    }
    selectPod(null);
  }, []);

  const onPodClicked = useCallback<NonNullable<MarkerClickHandler>>(
    (ev) => {
      const maybeSelectedPod = podData.find((pod) => {
        return pod.localRef === ev.mapFeatures[0]?.properties?.localRef;
      });
      maybeSelectedPod && selectPod(maybeSelectedPod);
    },
    [podData],
  );

  const onCameraMoved = useCallback(
    (cam: MapCamera) => {
      mp?.registerSuperProperties({
        map_latitude: cam.lat,
        map_longitude: cam.lng,
        map_zoom: cam.zoom,
        map_pitch: cam.pitch,
        map_bearing: cam.bearing,
        map_building: cam.buildingRef,
        map_floor: cam.floorIndex,
      });
    },
    [mp],
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: { flex: 1, backgroundColor: colors.background },
        smartMapContainer: {
          backgroundColor: colors.background,
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        },
        smartMap: {
          width: '100%',
          height: '100%',
          backgroundColor: colors.background,
        },
        floorBadge: {
          borderColor: colors.secondary,
          borderWidth: 2,
          color: colors.secondary,
          backgroundColor: colors.surface,
          // To get text centered on iOS. Size - 2 x border = 16
          lineHeight: Platform.OS === 'ios' ? 16 : undefined,
        },
      }),
    [colors],
  );

  const onBuildingChanged = useCallback(
    async (building: string) => {
      const focusedBuilding: Maybe<Building> | undefined = buildingData.data?.data?.getBuildingsWithinRadius?.find(
        (e) => e?.buildingRef === building,
      );

      if (focusedBuilding?.timezone && focusedBuilding.timezone !== timeZone) {
        onTimeZoneChange(focusedBuilding.timezone);
        // Always hide timezone dialog, as it is not currently relevant
        const dontShowTimezoneDialog = true;
        // const dontShowTimezoneDialog = await getData(InternalStorageItemKey.DONT_SHOW_TIMEZONE_DIALOG);
        // Only show dialog when time zone is different that the time zone of the device.
        if (focusedBuilding.timezone !== getTimeZone() && !dontShowTimezoneDialog) {
          navigation.navigate('TimezoneWarningDialog');
        }
      }

      setCurrentBuilding(building);
    },
    [buildingData.data?.data?.getBuildingsWithinRadius, timeZone, onTimeZoneChange, navigation],
  );

  const onFloorChanged = useCallback(
    (building: string | undefined, floor: number | undefined) => {
      if (building && building !== currentBuilding) {
        onBuildingChanged(building);
      }
    },
    [currentBuilding, onBuildingChanged],
  );

  const floorSwitcherBadges = useMemo(() => {
    return mapPoisWithReservationData.pois
      .filter((e) => e.properties.buildingRef === currentBuilding)
      .reduce((badgeInfo, poi) => {
        if (poi.properties.layerIndex !== null) {
          const isFree = poi.properties.podStatus === PodStatus.FREE;
          if (isFree) {
            if (!badgeInfo[poi.properties.layerIndex]) {
              /* eslint-disable no-param-reassign */
              badgeInfo[poi.properties.layerIndex] = {
                value: 1,
                style: styles.floorBadge,
              };
            } else {
              /* eslint-disable no-param-reassign */
              (badgeInfo[poi.properties.layerIndex].value as number) += 1;
            }
          }
        }
        return badgeInfo;
      }, {} as Record<number, FloorBadge>);
  }, [mapPoisWithReservationData.pois, currentBuilding, styles]);

  const qrCodeButton = useMemo(() => {
    if (Constants.manifest?.extra?.hideScanningSpaceQRCode) return null;
    return <QRCodeButton />;
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.smartMapContainer}>
        <SmartMap
          style={styles.smartMap}
          isHidden={!isFocused}
          insets={mapInsets}
          bluedotStatus={locationPermissionStatus}
          bluedotLocation={bluedotLocation}
          bluedotHeading={bluedotHeading}
          bluedotAccuracy={bluedotAccuracy}
          onBluedotPermissionNeeded={requestLocationPermissionAsync}
          onClick={onMapClick}
          onCameraMoved={onCameraMoved}
          onFloorChanged={onFloorChanged}
          floorBadges={floorSwitcherBadges}
          focusLocation={focusLocation}
          leftContent={qrCodeButton}
          bottomContent={!!selectedPod && <PodInformationCard pod={selectedPod} />}
        >
          {/* Note: ALL props of the marker cluster should be memoized if they are not primitive
           type to prevent unnecessary mount and unmount on maplibre level */}
          <MarkerCluster<AvailabilityMarkerVars, GeoJsonWithVenueAvailability, GJClusterProps>
            id="venues"
            features={mapPoisWithReservationData.venues}
            clusterProperties={venueAvailabilityClusterProperties}
            clusterRadius={100}
            zoomLevelOnSelect={BUILDING_SELECT_ZOOM}
            visibility={VisibilityMode.NotOnFloor}
            marker={generateAvailabilityMarker}
            markerVars={markerVars}
          />
          <MarkerCluster<AvailabilityMarkerVars, GeoJsonWithReservation, GJClusterProps>
            id="pods"
            features={mapPoisWithReservationData.pois}
            clusterProperties={podAvailabilityClusterProperties}
            clusterRadius={100}
            zoomLevelOnSelect={POD_SELECT_ZOOM}
            visibility={VisibilityMode.OnFloor}
            marker={generateAvailabilityMarker}
            markerVars={markerVars}
            onClick={onPodClicked}
          />
        </SmartMap>
      </View>

      <NotificationBanner
        onBannerHeightChange={(height) => {
          setMapInsets((prev) => {
            return { ...prev, top: height };
          });
        }}
      />
      <Snackbar
        duration={Snackbar.DURATION_MEDIUM}
        onDismiss={() => {
          setSnackbarVisible(false);
        }}
        visible={snackbarVisible}
        action={{
          label: I18n.t('mapview.banner.dismiss'),
          onPress: () => {
            setSnackbarVisible(false);
          },
        }}
        theme={{ colors: { accent: colors.primary } }}
      >
        {I18n.t('mapview.locationPermissionDenied')}
      </Snackbar>

      <NotificationPermissionDialog
        isVisible={notificationModalIsVisible}
        onActionPress={() => {
          requestNotificationPermission().then((res) => {
            if (res === 'granted') {
              initAppPushNotification();
            }
            setNoficationModalIsVisible(false);
          });
        }}
        onDismiss={() => {
          setNoficationModalIsVisible(false);
        }}
      />
      {!!modalResources && (
        <SelectResourceModal
          resources={modalResources}
          isVisible={!!modalResources}
          loading={loading}
          onDismiss={() => setModalResources(null)}
        />
      )}
    </View>
  );
};
